import { Controller, useForm } from 'react-hook-form'
import { CustomStyledInput, ValidationInputComponent } from '../../global/ValidationInputComponent'
import {
  AuthInputWrapper,
  ErrorText,
  FlexRow,
  InputAndErrorWrapper,
  InputTitle,
  PassIcon,
  StyledRegisterButton as StyledRegisterButtonDef,
  StyledButton as StyledButtonDef,
} from '../../../style/projectComponents'
import {
  StyledRegisterButton as StyledRegisterButtonTime,
  StyledButton as StyledButtonTime,
} from '../../../style/projectTimeComponents'
import { FC, useContext, useEffect, useMemo, useState } from 'react'
import styled, { useTheme } from 'styled-components/macro'
import { useTranslation } from 'react-i18next'
import { useMutation } from 'react-query'
import AuthApi from '../../../service/auth-api/AuthApi'
import { IAuthSignupRequest } from '../../../service/auth-api/models'
import { useNavigate, useSearchParams } from 'react-router-dom'
import UserStorage from '../../../storage/UserStorage/UserStorage'
import { finalInputsDef, finalInputsTime, isPhoneNumberGood } from './SecUtils'
import jwtDecode from 'jwt-decode'
import GlobalModalStore from '../../../storage/GlobalModalStore/GlobalModalStore'
import formatDateCustom from '../../../utils/formatDateCustom'
import InputMask from 'react-input-mask'
import { INITIAL_STATE_REG, RegisterContext } from '../../../pages/RegisterPage'
import { PROJECT_NAME } from '../../../config/serverConstants'
import { StyledSelect } from '../../global/StyledSelect'
import { countryCodes } from '../../../config/phones'
import { usePostApiAuthSendEmailConfirmationCode } from '../../../service/api/generated'

const StyledRegisterButton =
  PROJECT_NAME === 'Def' ? StyledRegisterButtonDef : StyledRegisterButtonTime
const StyledButton = PROJECT_NAME === 'Def' ? StyledButtonDef : StyledButtonTime

type IFormValues = {
  phoneNumber: string
  password: string
  email: string
  retypePassword: string
  badSignup: any
  receivedReferralCode: string
  code?: string
}

export type IFinalInput = {
  name:
    | 'phoneNumber'
    | 'password'
    | 'email'
    | 'retypePassword'
    | 'badSignup'
    | 'receivedReferralCode'
    | 'code'
  label: string
  type: string
  rules: any
}

const StyledAdvise = styled.p`
  margin-top: 10px;
  color: ${({ theme }) => theme.colors.gray};
  font-size: 12px;
  font-weight: 400;
  text-align: left;
  width: 440px;
  min-height: 14px;
  @media (max-width: 768px) {
    width: 100%;
  }
`

const PhoneWrapper = styled(FlexRow)`
  width: 100%;
  box-sizing: border-box;
  padding: 0;
  gap: 6px;
  min-width: 390px;
  @media screen and (max-width: 500px) {
    flex-wrap: wrap;
    &:first-child {
      min-width: 150px;
    }
  }
`

const MaskWrapper = styled(FlexRow)`
  width: 370px;
  @media screen and (max-width: 500px) {
    width: 100%;
  }
`

const Form = styled.form`
  @media screen and (max-width: 480px) {
    margin-top: 200px;
  }
`

export const SecondStep: FC = () => {
  const navigate = useNavigate()

  const UserStore = useContext(UserStorage)
  const { formUser, setFormUser } = useContext(RegisterContext)
  const [searchParams, setSearchParams] = useSearchParams()
  const refCode = searchParams.get('ref') || ''

  const {
    control,
    clearErrors,
    getValues,
    resetField,
    setError,
    register,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm<IFormValues>({
    defaultValues: {
      phoneNumber: formUser.phoneNumber,
      password: formUser.password,
      email: formUser.email,
      retypePassword: '',
      receivedReferralCode: refCode,
      code: null,
    },
  })

  const { t, i18n } = useTranslation()

  const [sendEmailTimer, setSendEmailTimer] = useState(0)

  const GlobalModalStorage = useContext(GlobalModalStore)
  const { mutate: sendCodeMutate, isLoading: sendCodeIsLoading } =
    usePostApiAuthSendEmailConfirmationCode({
      mutation: {
        onSuccess: () => {
          setSendEmailTimer(120)
          GlobalModalStorage.makeVisible(true, t('auth.successCode'))
        },
        onError: (error: any) => {
          GlobalModalStorage.makeVisible(false, error?.response?.data?.message)
        },
      },
    })
  const sendCodeButtonTitle =
    (sendCodeIsLoading && t('common.loading')) ||
    t(sendEmailTimer > 0 ? 'auth.codeSent' : 'auth.sendCode')

  useEffect(() => {
    let timerInterval
    if (sendEmailTimer > 0) {
      timerInterval = setInterval(() => {
        setSendEmailTimer(prevTimer => prevTimer - 1) // Уменьшаем таймер на 1 каждую секунду
      }, 1000)
    }

    // Очистка таймера при размонтировании компонента или когда таймер достиг нуля
    return () => {
      clearInterval(timerInterval)
    }
  }, [sendEmailTimer])

  const sendCode = () => {
    sendCodeMutate({ data: { email: getValues('email') } })
  }

  const signupMutation = useMutation((data: IAuthSignupRequest) => AuthApi.signup(data), {
    onError: (error: any) => {
      setError('badSignup', {
        type: 'manual',
        message: 'auth.errors.password.serverError',
      })
      if (error?.response?.data?.errorCode === 'ERR_AUTH_USER_EXIST') {
        GlobalModalStorage.makeVisible(false, t('auth.errors.password.serverRegErrorUserExist'))
      } else GlobalModalStorage.makeVisible(false, error?.response?.data?.message)
    },
    onSuccess: data => {
      UserStore.setTokens(data)
      UserStore.setUser(jwtDecode(data.accessToken))
      setFormUser(INITIAL_STATE_REG)
      navigate('/dashboard')
    },
  })
  const onSubmit = data => {
    setFormUser({
      ...formUser,
      phoneNumber: data.phoneNumber,
      email: data.email,
      password: data.password,
      receivedReferralCode: data.receivedReferralCode,
      code: data.code,
    })
    const birthDate = formUser.birthDate
    const sendData = {
      ...formUser,
      phoneNumber: data.phoneNumber,
      email: data.email,
      password: data.password,
      receivedReferralCode:
        (data.receivedReferralCode?.length > 0 && data.receivedReferralCode) || null,
      code: data.code,
    }
    sendData.birthDate = formatDateCustom(birthDate)
    signupMutation.mutate(sendData)
  }
  const phone: IFinalInput = {
    name: 'phoneNumber',
    label: 'auth.phone',
    type: 'phone',
    rules: isPhoneNumberGood,
  }

  const [itemId, setItemId] = useState(0)
  const [openMenu, setOpenMenu] = useState(false)

  const MaskPhoneInput = props => {
    // const idx = phoneCodes.at(itemId).split(' ').at(0)
    const idx = countryCodes[itemId].code
    return (
      <InputMask
        style={{ direction: 'ltr' }}
        mask={`(${idx}) 999-999-99-99`}
        {...props}
        value={props.value}
        onChange={props.onChange}
      >
        {inputProps => <CustomStyledInput {...inputProps} />}
      </InputMask>
    )
  }

  const [isClicked, setIsClicked] = useState(true)
  const handleClick = () => {
    setIsClicked(!isClicked)
  }

  const [showPass, setShowPass] = useState(false)
  const [showRetypePass, setShowRetypePass] = useState(false)

  const handleCheckType = (name: string, typeDefault: any) => {
    switch (name) {
      case 'password':
        return showPass ? 'text' : 'password'
      case 'retypePassword':
        return showRetypePass ? 'text' : 'password'
      default:
        return typeDefault
    }
  }

  const advisePassword = 'settings.inputs.password.errors.pattern'
  const theme = useTheme()
  const customStyles = {
    menu: (provided, state) => ({
      ...provided,
      backgroundColor: theme.colors.inputBackground,
    }),
  }
  const phonesCodesForSelect = useMemo(
    () => countryCodes?.map((n, idx) => ({ label: `${n.code} ${n.name}`, value: idx })),
    []
  )
  type SelectLabelItem = {
    label: string
    value: number
  }
  const [phonesCode, setPhonesCode] = useState<SelectLabelItem>(phonesCodesForSelect?.[0])
  const isEmailValid = email => {
    const emailValidation = /^[^@ ]+@[^@ ]+\.[^@ .]{2,}$/
    return emailValidation.test(email)
  }

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <AuthInputWrapper style={{ marginBottom: 10 }}>
        <Controller
          rules={phone.rules}
          name={phone.name}
          control={control}
          render={({ field: { ref, ...field } }) => (
            <PhoneWrapper>
              <StyledSelect
                styles={customStyles}
                options={phonesCodesForSelect}
                classNamePrefix={'Select'}
                value={phonesCode}
                onChange={e => {
                  const event = e as SelectLabelItem
                  setPhonesCode({ label: event.label, value: event.value })
                  setItemId(event.value)
                }}
              />
              <MaskWrapper>
                <MaskPhoneInput
                  {...field}
                  isFailed={!!errors[phone.name]}
                  className={'phone'}
                  name={phone.name}
                  placeholder={t('adminUserDetail.phoneNumber.label')}
                  isError={!!errors[phone.name]}
                />
              </MaskWrapper>
            </PhoneWrapper>
          )}
        />
      </AuthInputWrapper>
      <ErrorText hasError={!!errors[phone.name]} style={{ maxWidth: 300, marginBottom: 10 }}>
        {t(errors[phone.name]?.message?.toString())}
      </ErrorText>
      {(PROJECT_NAME === 'Def' ? finalInputsDef : finalInputsTime).map((it, idx) => (
        <InputAndErrorWrapper key={idx}>
          <InputTitle style={{ width: '100%', textAlign: 'left' }}>{t(it.label)}</InputTitle>
          <AuthInputWrapper style={{ marginBottom: 5, marginTop: idx !== 0 && 10 }}>
            <Controller
              name={it.name}
              control={control}
              rules={it.name === 'retypePassword' ? it.rules(watch) : it.rules}
              render={({ field: { ref, ...field } }) => (
                <>
                  <ValidationInputComponent
                    {...field}
                    name={it.name}
                    type={handleCheckType(it.name, it.type)}
                    isError={!!errors[it.name]}
                    label={t(it.label)}
                  />
                  {PROJECT_NAME === 'Def' &&
                    it.name === 'email' &&
                    isEmailValid(getValues('email')) && (
                      <StyledButton
                        type="button"
                        onClick={sendCode}
                        disabled={
                          sendCodeIsLoading ||
                          !isEmailValid(getValues('email')) ||
                          sendEmailTimer > 0
                        }
                        style={{ height: '100%', marginLeft: 6, borderRadius: 16 }}
                      >
                        {sendCodeButtonTitle}
                      </StyledButton>
                    )}
                </>
              )}
            />
            {it.name === 'password' && (
              <PassIcon
                onClick={() => setShowPass(prev => !prev)}
                src={showPass ? '/images/show.svg' : '/images/hide.svg'}
              />
            )}

            {it.name === 'retypePassword' && (
              <PassIcon
                onClick={() => setShowRetypePass(prev => !prev)}
                src={showRetypePass ? '/images/show.svg' : '/images/hide.svg'}
              />
            )}
          </AuthInputWrapper>
          {it.name === 'email' && sendEmailTimer > 0 && (
            <StyledAdvise>
              {t('auth.newCodeCanBeSentIn')} {sendEmailTimer} {t('common.sec')}
            </StyledAdvise>
          )}
          {it.name === 'password' && !errors[it.name] && (
            <StyledAdvise>{t(advisePassword)}</StyledAdvise>
          )}
          <ErrorText hasError={!!errors[it.name]} style={{ maxWidth: 300 }}>
            {t(errors[it.name]?.message?.toString())}
          </ErrorText>
        </InputAndErrorWrapper>
      ))}

      <StyledRegisterButton onClick={() => clearErrors()} type={'submit'} disabled={!isClicked}>
        {t('auth.reg_title')}
      </StyledRegisterButton>
    </Form>
  )
}
